<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import router from '../../../../index';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { required } from '@vuelidate/validators';
import { loadScript } from 'dashboard/helper/DOMHelpers';
import * as Sentry from '@sentry/vue';

/* global FB */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    LoadingState,
  },
  data() {
    return {
      inboxName: '',
      isCreating: false,
      loadingStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      loginStarted: false,
      apiKey: '',
      acceptedWhatsappComercialPolice: false,
      acceptedWhatsappBusinessPolice: false,
      selectedNumber: null,
    };
  },
  computed: {
    loginOrCreationHasStarted() {
      return this.loginStarted || this.isCreating;
    },
    hasAcceptedPolicies() {
      return (
        this.acceptedWhatsappComercialPolice &&
        this.acceptedWhatsappBusinessPolice &&
        !this.v$.$invalid
      );
    },
  },
  validations: {
    inboxName: { required },
  },
  mounted() {
    window.fbAsyncInit = this.runFBInit;
    this.setupMessageListener();
  },

  methods: {
    async startLogin() {
      if (!this.hasAcceptedPolicies) {
        this.v$.$touch();
        return;
      }

      this.loginStarted = true;
      this.loadingStateMessage = this.$t('INBOX_MGMT.DETAILS.LOADING_FB');

      try {
        await this.loadFBsdk();
        this.runFBInit();
        this.tryFBlogin();
      } catch (error) {
        this.loginStarted = false;

        if (error.name === 'ScriptLoaderError') {
          useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_LOADING'));
        } else {
          Sentry.captureException(error);
          useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
        }
      }
    },

    runFBInit() {
      FB.init({
        appId: window.vordeskConfig.fbAppId,
        xfbml: true,
        version: 'v22.0',
        autoLogAppEvents: true,
        status: true,
      });
      window.fbSDKLoaded = true;
      FB.AppEvents.logPageView();
    },

    async loadFBsdk() {
      return loadScript('https://connect.facebook.net/en_US/sdk.js', {
        id: 'facebook-jssdk',
      });
    },

    tryFBlogin() {
      FB.login(
        response => {
          (async () => {
            this.loginStarted = false;

            if (response.status === 'connected') {
              this.apiKey = response.authResponse.code;
              this.createChannel();
            } else if (response.status === 'not_authorized') {
              useAlert(`${this.$t('INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED')}\n${this.$t('INBOX_MGMT.DETAILS.ERROR_FB_UNAUTHORIZED_HELP')}`);              
            } else {
              useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
            }
          })();
        },
        {
          config_id: window.vordeskConfig.fbConfigId,
          response_type: 'code',
          override_default_response_type: true,
          extras: {
          setup: {},
            featureType: '',
            sessionInfoVersion: '3',
          }
        }
      );
    },

    setupMessageListener() {
      window.addEventListener('message', (event) => {
        if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') return;

        try {
          const data = JSON.parse(event.data);

          if (data.type !== 'WA_EMBEDDED_SIGNUP') return;
          if (data.event === 'FINISH') this.selectedNumber = data.data;
          else if (data.event === 'CANCEL') useAlert(this.$t('INBOX_MGMT.DETAILS.CANCEL_FB_LOGIN'));
        } catch (error) {
          console.log('Message event parsing failed: ', event.data);
        }
      });
    },

    async createChannel() {
      if (!this.selectedNumber) return;

      this.isCreating = true;
      this.loadingStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');

      try {
        const config = {
          api_key: this.apiKey,
          phone_number_id: this.selectedNumber?.phone_number_id,
          inbox_name: this.inboxName,
          waba_id: this.selectedNumber?.waba_id,
        };

        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'whatsapp',
              provider: 'whatsapp_signin',
              provider_config: config,
            },
          }
        );

        this.isCreating = false;

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.isCreating = false;
        useAlert(
          error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: v$.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model="inboxName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="v$.inboxName.$touch"
        />
        <span v-if="v$.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>
    <div v-if="!loginOrCreationHasStarted" class="flex flex-col">
      <div class="mt-4">
        <div class="flex items-center gap-x-4">
          <div style="flex-basis:55%;">
            <h1
              class="text-xl text-slate-800 dark:text-slate-100 mb-6 text-justify"
            >
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_BUSINESS.TITLE') }}
            </h1>

            <p
              class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 text-justify" style="max-width:90%;"
            >
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_BUSINESS.DESCRIPTION') }}
            </p>
          </div>

          <div
            class="mx-8 w-1 h-14 bg-green-500 dark:bg-slate-300 rounded-md"
          />

          <div class="flex flex-col" style="flex-basis:45%;">
            <a
              href="https://faq.whatsapp.com/933578044281252"
              target="_blank"
              rel="noopener noreferrer"
              class="no-underline visited:text-slate-700 hover:cursor-pointer rounded-lg border-slate-500 border-2 px-4 py-2 flex items-center gap-x-2 self-end mb-4"
            >
              <p
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 font-semibold visited:text-slate-600 hover:text-green-500"
              >
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.POLICIES.FAQ') }}
              </p>
              <fluent-icon
                icon="link"
                icon-lib="lucide"
                :size="22"
                class="inline cursor-pointer"
              />
            </a>
            <div class="flex items-center gap-2 cursor-pointer">
              <input
                v-model="acceptedWhatsappComercialPolice"
                type="checkbox"
                class="m-0 cursor-pointer"
              />
              <a
                href="https://www.whatsapp.com/legal/commerce-policy/?lang=pt_br"
                target="_blank"
                rel="noopener noreferrer"
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 no-underline visited:text-slate-600 hover:text-green-500"
              >
                {{
                  $t(
                    'INBOX_MGMT.ADD.WHATSAPP.POLICIES.AGREE_WITH_COMERCIAL_POLICY'
                  )
                }}
              </a>
            </div>
            <div class="flex items-center gap-2 cursor-pointer mb-2">
              <input
                v-model="acceptedWhatsappBusinessPolice"
                type="checkbox"
                class="m-0 cursor-pointer"
              />
              <a
                href="https://business.whatsapp.com/policy"
                target="_blank"
                rel="noopener noreferrer"
                class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 no-underline visited:text-slate-600 hover:text-green-500"
              >
                {{
                  $t(
                    'INBOX_MGMT.ADD.WHATSAPP.POLICIES.AGREE_WITH_BUSINESS_POLICY'
                  )
                }}
              </a>
            </div>

            <div>
              <span
                class="m-0 p-0 font-semibold text-sm text-slate-800 dark:text-slate-100"
              >
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.ATTENTION_VERIFICATION_CODE') }}
              </span>
              <span class="p-0 m-0 text-sm text-slate-600 dark:text-slate-300">
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.SAVE_VERIFICATION_CODE') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <hr class="m-8" />

      <div class="flex flex-col gap-y-10">
        <div style="max-width:55%">
          <h2
            class="text-xl text-slate-800 dark:text-slate-100 mb-4 text-justify"
          >
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WITH_FACEBOOK.TITLE') }}
          </h2>

          <p
            class="m-0 p-0 text-sm text-slate-600 dark:text-slate-300 text-justify"
            style="max-width: 90%"
          >
            {{
              $t('INBOX_MGMT.ADD.WHATSAPP.CONFIGURE_WITH_FACEBOOK.DESCRIPTION')
            }}
          </p>
        </div>

        <button
          class="join-with-facebook"
          :class="
            hasAcceptedPolicies
              ? 'join-with-facebook-enabled'
              : 'join-with-facebook-disabled'
          "
          @click="startLogin"
        >
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.LOGIN_WITH_FACEBOOK') }}
        </button>
      </div>
    </div>
    <LoadingState v-if="loginOrCreationHasStarted" :message="loadingStateMessage" />
  </div>
</template>

<style lang="scss" scoped>
.join-with-facebook {
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 240px;
  padding: 0 12px;
  font-family: Helvetica, Arial, sans-serif;
}

.join-with-facebook-enabled {
  cursor: pointer;
  background-color: rgb(24, 119, 242);
  color: #fff;
}

.join-with-facebook-disabled {
  background-color: rgb(225, 222, 222);
  color: slategray;
}
</style>
